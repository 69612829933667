interface IPath {
  name: string,
  path: string,
  regex: RegExp,
}

const HOME_PAGE = {
  name: 'Homepage',
  path: '/',
  regex: /^\/(?:[a-z]{2}\/)?(\?.+)?$/,
};
const PRODUCTS_PAGE = {
  name: 'Products',
  path: '/products/:verticalId',
  regex: /^\/(?:[a-z]{2}\/)?(products|productos)(\?.+)?$/,
};
const VERTICAL_PAGE = {
  name: 'Vertical',
  path: '/products/:verticalId',
  regex: /^\/(?:[a-z]{2}\/)?[^/]+\/[^/]+(\?.+)?$/,
};
const CLUSTER_PAGE = {
  name: 'Cluster',
  path: '/products/:verticalId/:clusterId',
  regex: /^\/(?:[a-z]{2}\/)?[^/]+\/[^/]+\/[^/]+(\?.+)?$/,
};

const PATHS: { [key: string]: IPath } = {
  HOME_PAGE,
  PRODUCTS_PAGE,
  VERTICAL_PAGE,
  CLUSTER_PAGE,
};

const UNKNOWN_PATH = {
  name: undefined,
};

export const getPath = (currentPath: string) => {
  let path;

  Object.keys(PATHS).forEach((key) => {
    if (PATHS[key].regex.test(currentPath)) path = PATHS[key];
  });

  return path ?? UNKNOWN_PATH;
};
