import { setCookies, getCookie, checkCookies } from 'cookies-next';
import { addDays } from 'date-fns';
import { TrackingPayload } from 'types/tracking.type';
import { capitalize } from './common';
import {
  linkedinConversions, TABOOLA_ADS_KEY, TrackEvents,
} from './constants';
import global from './global';

const COOKIE_UTM_KEY = 'dh_utm';
const COOKIE_UTM_QUERYSTRING_KEYS = [
  'utm_source',
  'utm_term',
  'utm_content',
  'utm_medium',
  'utm_campaign',
];
const ALLOWED_QUERYSTRING_CHECKOUT_KEYS = [...COOKIE_UTM_QUERYSTRING_KEYS, 'gclid'];
const ALLOWED_QUERYSTRING_KEYS = [...ALLOWED_QUERYSTRING_CHECKOUT_KEYS, 'oferta'];

const COOKIE_UTM_EXPIRES = 7;

const analyticsLocaleKey = {
  'es-AR': process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY_AR,
  'pt-BR': process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY_BR || process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
  'es-CO': process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY_CO,
  'es-MX': process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY_MX,
  'es-CL': process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY_CL,
  es: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY_LAT,
};

export const getAnalyticsLocaleKey = (locale: string): string => analyticsLocaleKey[locale] || analyticsLocaleKey.es;

const trackError = () => {
  throw new Error(
    'Analytics React SDK: An error occurred trying to track event.',
  );
};

/**
 * Segment page method
 */
export const page = (analytics, name?): void => {
  if (!analytics?.page) return;
  try {
    analytics.page(name);
  } catch (e) {
    trackError();
  }
};

/**
 * Segment identify method
 */
export const identify = (analytics, payload: object): void => {
  if (!analytics?.identify) return;
  try {
    analytics.identify(payload);
  } catch (e) {
    trackError();
  }
};

/**
 * LinkedIn Track
 */
const linkedinTrack = ({ locale, eventName }): void => {
  const conversionEvents = linkedinConversions[locale] && linkedinConversions[locale][eventName];
  const conversionIds = conversionEvents?.split(',').map((conversion) => +conversion);

  if (conversionIds?.length) {
    conversionIds.forEach((conversionId) => {
      if (conversionId && global?.lintrk) {
        global.lintrk('track', { conversion_id: conversionId });
      }
    });
  }
};

/**
 * Taboola Ads Track
 */
const taboolaTrack = ({ locale, eventName }): void => {
  const taboolaKey = +TABOOLA_ADS_KEY[locale];
  // eslint-disable-next-line no-underscore-dangle
  if (global?._tfa && taboolaKey) {
    // eslint-disable-next-line no-underscore-dangle
    global._tfa.push({ notify: 'event', name: eventName.replaceAll(' ', '_'), id: taboolaKey });
  }
};

/**
 * Segment track method
 */
export const track = (analytics, eventName: string, payload: TrackingPayload, locale: string): void => {
  if (!analytics?.track) return;
  try {
    const payloadData = payload;
    if (eventName !== TrackEvents.productApplicationSubmitted) {
      delete payloadData.tracking;
    }

    analytics.track(eventName, payloadData);
    linkedinTrack({ eventName, locale });
    taboolaTrack({ eventName, locale });
  } catch (e) {
    trackError();
  }
};

export enum TrackPages {
  homepage = 'homepage',
  clusterPage = 'clusterpage',
  summaryPage = 'summarypage'
}

interface getTrackContextPops {
  type: string;
  businessUnit?: string;
}

export const getTrackContext = ({
  type,
  businessUnit,
}: getTrackContextPops): string => {
  if (!type) return 'Unknown';

  if (type === TrackPages.clusterPage) {
    return `${capitalize(businessUnit || 'course')} Page`;
  }
  if (type === TrackPages.summaryPage) {
    return `${capitalize(businessUnit || 'course')} Offer`;
  }

  return capitalize(type);
};

export const setUtm = (params): void => {
  const data = Object.keys(params)
    .filter((key) => COOKIE_UTM_QUERYSTRING_KEYS.includes(key))
    .reduce((value, key) => {
      const tempValue = value;
      tempValue[key] = params[key];
      return tempValue;
    }, {});

  setCookies(
    COOKIE_UTM_KEY,
    {
      ...data,
      url: params.url,
      date: new Date(),
    },
    {
      expires: addDays(new Date(), COOKIE_UTM_EXPIRES),
    },
  );
};

export const getUtm = (): { [key: string]: string } => {
  try {
    const data = getCookie(COOKIE_UTM_KEY);
    return JSON.parse(data as string);
  } catch (e) {
    return {};
  }
};

export const mustStoreUtm = (params): boolean => (
  !checkCookies(COOKIE_UTM_KEY)
  && Object.keys(params).some((key) => COOKIE_UTM_QUERYSTRING_KEYS.includes(key))
);

export const getAllowedQueryString = (params: { [key: string]: string } = {}, checkout = false) => Object.keys(params)
  .filter((key) => (checkout ? ALLOWED_QUERYSTRING_CHECKOUT_KEYS : ALLOWED_QUERYSTRING_KEYS).includes(key))
  .reduce((data, key) => {
    const tempData = data;
    tempData[key] = params[key];
    return tempData;
  }, {});

export const findGeoCountry = async () => {
  try {
    const res = await fetch(process.env.NEXT_PUBLIC_GEO_COUNTRY_URL ?? '');
    const data = await res.text();

    const { loc } = data.split('\n').reduce((acc, current) => {
      const parts = current.split('=');
      const [key, value] = parts;
      if (key) acc[key] = value;
      return acc;
    }, { loc: '' });

    return loc;
  } catch (e) {
    return '';
  }
};
